import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));



    window.Telegram.WebApp.expand();

    setTimeout(() => {
        root.render(<App />);
    }, 100); 
   
    
    

       




