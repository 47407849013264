import React, { useState, useEffect, useRef } from 'react';
import { useAppData } from '../../AppContext';
import { useTranslation } from 'react-i18next';
import Back from '../Back';
import Footer from '../Footer/Footer';
import "./leagues.scss";
import Background from '../../pages/background.jpg';
import Arrow from './back.png';

import Bronze from './Bronze.png';
import Silver from './Silver.png';
import Gold from './Gold.png';
import Platinum from './Platinum.png';
import Diamond from './Diamond.png';
import Master from './Master.png';
import Grandmaster from './Grandmaster.png';

import { getLeague } from '../../http';
export const Leagues = () => {
    const { t } = useTranslation();
    const { total_coins_earned, leagues } = useAppData();
    const leagueBar = useRef(null);
    const logos = [Bronze, Silver, Gold, Platinum, Diamond, Master, Grandmaster];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentLeagueData, setCurrentLeagueData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchData(currentIndex);
    }, [currentIndex, leagues]);

    const fetchData = async (index) => {
        if (index !== null && leagues[index]) {
            setLoading(true);
            setError('');
            try {
                const data = await getLeague('day', leagues[index].name);
                setCurrentLeagueData(data);
            } catch (err) {
                console.error('Error fetching league data:', err);
                setError('Failed to fetch league data.');
            }
            setLoading(false);
        }
    };

    const findCurrentLeagueIndex = () => {
        for (let i = leagues.length - 1; i >= 0; i--) {
            if (leagues[i].coin_limit === "max" || total_coins_earned >= leagues[i].coin_limit) {
                return i;
            }
        }
        return 0;
    };



    useEffect(() => {
        setCurrentIndex(findCurrentLeagueIndex());
    }, [total_coins_earned, leagues]);

    const navigateLeague = (direction) => {
        const newIndex = currentIndex + direction;
        if (newIndex >= 0 && newIndex < leagues.length) {
            setCurrentIndex(newIndex);
        }
    };


    useEffect(() => {
        if (leagueBar.current && currentLeagueData) {
            const league = leagues[currentIndex];
            const coin_limit = league.coin_limit === "max" ? total_coins_earned : league.coin_limit;
            const width = coin_limit === "max" ? 100 : (total_coins_earned / coin_limit) * 100;
            leagueBar.current.style.width = `${Math.min(width, 100)}%`;
        }
    }, [total_coins_earned, currentLeagueData]);

    const currentLeague = leagues[currentIndex] || {};

    return (
        <div className='leagueListContainer' style={{
            height: '100vh',
            overflow: 'hidden',
            backgroundImage: `url(${Background})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }}>
            {loading && <></>}
            {error && <p>Error: {error}</p>}
            <Back />
            <div className="leagueText">
                <h1>{currentLeague.name} League</h1>
                <h3>Your number of shares determines the league you enter.</h3>
            </div>
            <div className="trophy">
                <img src={logos[currentIndex]} alt={`${currentLeague.name} trophy`} />
            </div>
            <div className="leagueBar">
                <div className="energy-counter">
                    {currentLeague.coin_limit === "max"
                        ? <span>MAX</span>
                        : <>{total_coins_earned}/{currentLeague.coin_limit}</>
                    }
                </div>
                <div className="energy-bar" ref={leagueBar} />
            </div>
            <div className="league-navigation">
                <button onClick={() => navigateLeague(-1)} disabled={currentIndex === 0}>
                    <img src={Arrow} alt="Previous" />
                </button>
                <button onClick={() => navigateLeague(1)} disabled={currentIndex === leagues.length - 1}>
                    <img src={Arrow} alt="Next" />
                </button>
            </div>
            <h2>League Players:</h2>
            {currentLeagueData ? (
                currentLeagueData.league.map((player, index) => (
                    <div key={player.telegram_id} className='leaguePlayer'>
                        <h3>{index + 1}</h3>
                        <p>{player.name}</p>
                        <p> {player.total_earned}</p>
                    </div>
                ))
            ) : (
                <p>No data or still loading...</p>
            )}
            <Footer />
        </div>
    );
};
