import React from 'react';
import { useTranslation } from 'react-i18next';
import "./loading.scss";
import loading from "./loading.jpg"
import { useAppData } from '../../AppContext';

function Loading() {

    return (
        <div className='loading'>
            <img src={loading} style={{ width: "100%", height:"100%"}}></img>
        </div>
    );
}

export default Loading;
